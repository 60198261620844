<template>
    <div>
      <div class="section-title section-title--2 mb-10">
        <!-- <h4 class="heading-title">Log Monitoring</h4> -->
        <h3 class="heading-title mb-5">
          Log Monitoring
        </h3>
      </div>
      <div>
        <p style="font-size: 20px; color: #717173">Turn unstructured data into a valuable asset by parsing, transforming, and enriching logs for use cases for all teams and every technology stack irrespective of source. Improve query performance of your structured log data with schema on write, or take advantage of the benefits of schema on read with runtime fields to extract, calculate, and transform fields at query time.</p>
      </div>
    </div>
</template>
<script>
  
  export default {
    components: {
    //  component
    },
    data() {
      return {
        features: [
          {
            // iconFeatures: require("../assets/images/icons/logo-icon.png"),
            iconFeatures: require("../../assets/images/icons/monitor.svg"),
            titleFeatures: "Vessel Traffic Monitoring",
          },
          {
            // iconFeatures: require("../assets/images/icons/logo-icon.png"),
            iconFeatures: require("../../assets/images/icons/filter.svg"),
            titleFeatures: "Vessel Search & Filtering",
          },
          {
            // iconFeatures: require("../assets/images/icons/logo-icon.png"),
            iconFeatures: require("../../assets/images/icons/info.svg"),
            titleFeatures: "Vessel Voyage & Port Information Panel",
          },
          {
            // iconFeatures: require("../assets/images/icons/logo-icon.png"),
            iconFeatures: require("../../assets/images/icons/play.svg"),
            titleFeatures: "Playback Voyage",
          },
          {
            // iconFeatures: require("../assets/images/icons/logo-icon.png"),
            iconFeatures: require("../../assets/images/icons/database.svg"),
            titleFeatures: "Real-time Data Source Processing",
          },
          {
            // iconFeatures: require("../assets/images/icons/logo-icon.png"),
            iconFeatures: require("../../assets/images/icons/vessel.svg"),
            titleFeatures: "Vessel Even",
          },
          {
            // iconFeatures: require("../assets/images/icons/logo-icon.png"),
            iconFeatures: require("../../assets/images/icons/cloud.svg"),
            titleFeatures: "Weather Forecast",
          },
          {
            // iconFeatures: require("../assets/images/icons/logo-icon.png"),
            iconFeatures: require("../../assets/images/icons/bell.svg"),
            titleFeatures: "Custom Alert & Notification",
          },
          {
            // iconFeatures: require("../assets/images/icons/logo-icon.png"),
            iconFeatures: require("../../assets/images/icons/pie-chart.svg"),
            titleFeatures: "Analytics & Reporting",
          },
        ],
        // features2: [
        //   {
        //     // iconFeatures: require("../assets/images/icons/logo-icon.png"),
        //     iconFeatures: require("../../assets/images/icons/vessel.svg"),
        //     titleFeatures: "Vessel Even",
        //   },
        //   {
        //     // iconFeatures: require("../assets/images/icons/logo-icon.png"),
        //     iconFeatures: require("../../assets/images/icons/cloud.svg"),
        //     titleFeatures: "Weather Forecast",
        //   },
        //   {
        //     // iconFeatures: require("../assets/images/icons/logo-icon.png"),
        //     iconFeatures: require("../../assets/images/icons/bell.svg"),
        //     titleFeatures: "Custom Alert & Notification",
        //   },
        //   {
        //     // iconFeatures: require("../assets/images/icons/logo-icon.png"),
        //     iconFeatures: require("../../assets/images/icons/pie-chart.svg"),
        //     titleFeatures: "Analytics & Reporting",
        //   },
        // ]
      };
    },
  };
</script>
<style scoped>
.kolom-fitur {
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid white;
  border-radius: 5px;
  height: 125px;
  width: 190px;
}
</style>