<template>
  <v-row class="mx-10">
    <v-col lg="4" md="4" sm="12" cols="12" v-for="pricing in pricingContent" :key="pricing.id"
      class="d-flex justify-center">
      <div class="rn-pricingServicesSecurity pa-0 mb-10" :class="{ active: pricing.active }"
        style="height: fit-content !important; width: 100% !important;">
        <v-card class="pricing-table-inner">
          <div style="width: 100%; display: flex; justify-content: center; align-items: center;">
            <v-img :src="pricing.img" style="height: 200px; margin-top: 10px;"></v-img></div>
          <v-card-title>
            <div class="pricing-header">
              <h4 class="heading-title">{{ pricing.title }}</h4>
            </div>
          </v-card-title>
          <v-card-text>
            <span style="font-size:18px" v-html="pricing.desc"></span>
          </v-card-text>
        </v-card>
      </div>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      pricingContent: [
        {
          id: 1,
          title: "Master Sensor Appliance",
          desc: "System Load Balancing and High Availability <br/> User private key management for AAA",
          active: false,
          img: require("../../assets/images/contents/security/master_sensor_appliance.png"),
        },
        {
          id: 2,
          title: "Cloud Agent Sensor",
          desc: "Deep Packet Inspection & Metadata traffic capture <br/> Intrusion Detection and Prevention System",
          active: false,
          img: require("../../assets/images/contents/security/cloud_agent_sensor.png"),
        },
        {
          id: 3,
          title: "Central Log Management",
          desc: "Data log storing, categorize, and aggregate <br/> Data centralization from various sources",
          active: false,
          img: require("../../assets/images/contents/security/central_log_management.png"),
        },
        {
          id: 4,
          title: "Data Analytics",
          desc: "Centralized information for cross correlation & threat hunting <br/> Data indexing, visualization and discovery",
          active: false,
          img: require("../../assets/images/contents/security/data_analytics.png"),
        },
        {
          id: 5,
          title: "SIEM",
          desc: "Security Information correlation and event management with signature and behavioral detection",
          active: false,
          img: require("../../assets/images/contents/security/siem.png"),
        },
        {
          id: 6,
          title: "Dashboard Analytics",
          desc: "Data visualization from various sources <br/> Customizable Dashboard",
          active: false,
          img: require("../../assets/images/contents/security/dashboard_analytics.png"),
        },
      ],
    };
  },
};
</script>
